import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { RouterProvider, createBrowserRouter, Navigate } from 'react-router-dom';
import { DDQListPage } from './pages/list';
import { DDQResultsPage } from './pages/ddq-results';
import { SyncAuth } from './lib/sync-auth';
import { Identify } from './lib/identify';
import { CopyToken } from './lib/copy-token';
import { KeyboardManagerProvider } from './lib/keyboard-manager';
import GovernGPTErrorBoundary from './pages/error-boundary';
import DebugPage from './pages/debug';
import LockedEditorPage from './pages/locked-editor';

const ErrorBoundary = import.meta.env.DEV ? undefined : GovernGPTErrorBoundary;

const router = createBrowserRouter([
  {
    path: '/ddq/list',
    element: <DDQListPage />,
    ErrorBoundary
  },
  {
    path: '/ddq/:uuid/results',
    element: <DDQResultsPage />,
    ErrorBoundary
  },
  {
    path: '/locked-editor',
    element: (
      <LockedEditorPage
        initialValue={
          '<p data-locked="true"><i>Here\'s a question</i></p><p data-locked="true"><i>Testing</i></p><p data-locked="true"><i>Here are some instructions</i></p><table><tr><th data-locked="true">Heading</th></tr><tr><td>Content</td></tr></table>'
        }
      />
    )
  },
  ...(import.meta.env.DEV
    ? [
        {
          path: '/debug',
          element: <DebugPage />
        }
      ]
    : []),
  {
    path: '*',
    element: <Navigate to='/ddq/list' />,
    ErrorBoundary
  }
]);

const queryClient = new QueryClient();

const App: React.FC = () => (
  <>
      <SyncAuth />
      <Identify />
      <CopyToken />
      <QueryClientProvider client={queryClient}>
        <KeyboardManagerProvider>
          <RouterProvider router={router} />
        </KeyboardManagerProvider>
      </QueryClientProvider>
    </>
);

export default App;

