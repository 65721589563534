import React, { useEffect } from 'react';
import { useAtom } from 'jotai';
import { useAuthInfo } from '@propelauth/react';
import { regionAtom } from './use-region'; // Importing regionAtom from use-region-state

import { determineRegionFromAuth } from './use-region';

/**
 * SyncRegion component listens for changes in userClass and updates the regionAtom.
 * Usage:
 * - Use the useRegion hook in components like category.ts and export.tsx to access region and baseApiUrl.
 */
export const SyncRegion: React.ComponentType<React.PropsWithChildren> = ({
  children
}) => {
  const { userClass } = useAuthInfo();
  const [region, setRegion] = useAtom(regionAtom);

  useEffect(() => {
    const region = determineRegionFromAuth(userClass);
    const baseApiUrl =
      import.meta.env.MODE === 'production'
        ? region === 'eu'
          ? 'https://api.eu.governgpt.com/api'
          : 'https://api.governgpt.com/api'
        : import.meta.env.VITE_API_HOST;

    console.log('Region Config', { region, baseApiUrl });

    setRegion({ region, baseApiUrl, isSet: true });
  }, [userClass, setRegion]);

  if (region.isSet === true) return <>{children}</>;
  else return <></>;
};
