import cookie from 'js-cookie';
import { setAuthToken } from '@/register-worker';
import { useAuthInfo } from '@propelauth/react';
import React from 'react';
import { useAuthState } from './use-auth-state';

// We want to parse out the root domain for cross-subdomain cookies.
// If we explicitly need to avoid "www", we likely have to refine this further.
function getRootDomain(hostname: string): string {
  const parts = hostname.split('.');
  if (parts.length > 2) {
    return '.' + parts.slice(parts.length - 2).join('.');
  }
  return '.' + parts.join('.');
}

export const SyncAuth: React.ComponentType = () => {
  const { accessToken } = useAuthInfo();
  const { setAccessToken } = useAuthState();

  React.useEffect(() => {
    setAuthToken(accessToken);
    setAccessToken(accessToken);

    // Identify whether we're in production.
    const isProduction = process.env.NODE_ENV === 'production';

    // If the cookie is set on app.governgpt.com, it should also work on api.governgpt.com
    // Setting the cookie domain to the root-level domain for subdomain sharing.
    const domain = getRootDomain(window.location.hostname);

    // We use path = '/api' to limit cookie sending only to /api routes. 
    // If we need it beyond /api, we can adjust or remove this path.
    const cookieOptions = {
      domain,
      path: '/api',
      // SameSite=None requires Secure in modern browsers. However, for local development
      // this can be an issue if you're not serving over HTTPS. This snippet will use Lax if not in production.
      sameSite: (isProduction ? 'None' : 'Lax') as 'None' | 'Lax',
      secure: isProduction, // Secure must be true when SameSite=None in production
    };

    if (accessToken) {
      cookie.set('MEDIA_AUTH', accessToken, cookieOptions);
    } else {
      cookie.remove('MEDIA_AUTH', cookieOptions);
    }
  }, [accessToken, setAccessToken]);

  return <></>;
};
