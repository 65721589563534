//...
import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: 'https://1b3d66c357599c4e4a39810a65ad7b64@o4507663572992000.ingest.us.sentry.io/4507663582822400',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration()
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    /^http:\/\/127.0.0.1:8080\/api/,
    /^https:\/\/api\.governgpt\.com\/api/,
    /^https:\/\/api\.eu\.governgpt\.com\/api/
  ],
  // Session Replay
  replaysSessionSampleRate: import.meta.env.PROD ? 1.0 : 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: import.meta.env.MODE
});

import './lib/analytics.ts';
import ReactDOM from 'react-dom/client';
import { QueryClient } from '@tanstack/react-query';
import { RequiredAuthProvider, RedirectToLogin } from '@propelauth/react';

import './globals.css';
import { Loading } from './components/generic/loading.tsx';
import { Reset } from './lib/identify.tsx';

import './register-worker.ts';
import { queryClientAtom } from 'jotai-tanstack-query';
import { useHydrateAtoms } from 'jotai/react/utils';
import { FunctionComponent, ReactNode } from 'react';
import { WritableAtom } from 'jotai';
import { SyncRegion } from './lib/sync-region.tsx';
import App from './app.tsx';

const queryClient = new QueryClient();

// eslint-disable-next-line react-refresh/only-export-components
function AtomsHydrator({
  atomValues,
  children
}: {
  atomValues: Iterable<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    readonly [WritableAtom<unknown, [any], unknown>, unknown]
  >;
  children: ReactNode;
}) {
  useHydrateAtoms(new Map(atomValues));
  return children as ReturnType<FunctionComponent>;
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <AtomsHydrator atomValues={[[queryClientAtom, queryClient]]}>
    <RequiredAuthProvider
      authUrl={import.meta.env.VITE_APP_AUTH_URL}
      displayWhileLoading={<Loading />}
      displayIfLoggedOut={
        <Reset>
          <RedirectToLogin />
        </Reset>
      }
    >
      <SyncRegion>
        <App />
      </SyncRegion>
    </RequiredAuthProvider>
  </AtomsHydrator>
);
