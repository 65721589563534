import { UserClass } from '@propelauth/react';
import { atom, useAtomValue } from 'jotai';

// Default region to use if none is found in user metadata
export const defaultRegion = 'us';

/**
 * Determines the user's region based on the first organization's metadata.
 * Returns 'eu' only if the metadata region is exactly 'eu'; otherwise returns 'us'.
 */
export function determineRegionFromAuth(user: UserClass | null | undefined): string {
  if (user && typeof user.getOrgs === 'function') {
    const firstOrg = user.getOrgs()?.[0];
    const regionFromMetadata = firstOrg?.orgMetadata?.region;
    if (regionFromMetadata === 'eu') {
      return 'eu';
    }
  }
  return defaultRegion;
}

// Jotai atom to store region and baseApiUrl
export const regionAtom = atom<{ region: string; baseApiUrl: string, isSet: boolean }>({
  region: defaultRegion,
  baseApiUrl: import.meta.env.VITE_API_HOST,
  isSet: false
});

export const useRegion = () => {
  const regionData = useAtomValue(regionAtom);

  return {
    ...regionData
  };
};
